import { CoursesResponsData, ModuleContent, VideoHistory } from 'src/redux/API/Courses/types';

export const getPackPercentage = (data: CoursesResponsData) => {
  const allVideosSeenFromCurrentPack = data?.pack?.modules?.reduce((acc, cv): VideoHistory[] => {
    acc.push(
      ...data.videoHistory.filter((el) =>
        Object.values(cv.content).find((e) => e.id === el.VideoId && el.PackModuleId === cv.id)
      )
    );
    return acc as VideoHistory[];
  }, [] as VideoHistory[]);

  const allVideosFromCurrentPack = data?.pack?.modules?.reduce((acc, cv): ModuleContent[] => {
    return [
      ...acc,
      ...Object.values(cv.content).filter((e) => {
        return e.type === 'video';
      }),
    ] as ModuleContent[];
  }, [] as ModuleContent[]);

  const resultPercentage = Math.round(
    (((allVideosSeenFromCurrentPack ?? []).length ?? 0) * 100) / (allVideosFromCurrentPack ?? [])?.length
  );
  return resultPercentage;
};

export const getModulePercentage = (data: CoursesResponsData, moduleId: number) => {
  const seenVideosId = data?.videoHistory?.filter((data) => data.PackModuleId === moduleId).map((e) => e.VideoId);
  const allVideosFromCurrentPack = data?.pack?.modules?.reduce((acc, cv): ModuleContent[] => {
    return [
      ...acc,
      ...Object.values(cv.content).filter((e) => {
        return e.type === 'video' && cv.id === moduleId;
      }),
    ] as ModuleContent[];
  }, [] as ModuleContent[]);

  const filterJustSeenVideos = allVideosFromCurrentPack?.filter((element) => seenVideosId.indexOf(element.id) !== -1);

  const resultPercentage = Math.round(
    (((filterJustSeenVideos ?? []).length ?? 0) * 100) / (allVideosFromCurrentPack ?? [])?.length
  );
  return resultPercentage;
};
