import { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { AxiosError, AxiosRequestConfig } from 'axios';
import axiosInstance from './axiosInstance';
import { AppDispatch } from 'src/redux/store';
import { setTokens, localStorage } from 'src/ulils/localstorage';

interface CustomQueryArgs extends AxiosRequestConfig {
  onSuccess?: (dispatch: AppDispatch, data: unknown) => Promise<void>;
  onReject?: (dispatch: AppDispatch, data?: unknown) => Promise<void>;
}

export type CustomBaseQueryType = BaseQueryFn<CustomQueryArgs, unknown, unknown>;

export const axiosBaseQuery: CustomBaseQueryType = async ({ onSuccess, onReject, ...args }, { dispatch }) => {
  const token = localStorage({ key: 'token' }).getItem();

  const currentReq = async (accessToken: string) => {
    const result = await axiosInstance.request({
      ...args,
      headers: {
        ...args.headers,
        Authorization: accessToken,
      },
    });
    if (onSuccess) {
      try {
        await onSuccess(dispatch, result.data);
      } catch (e) {
        console.error('Error in onSuccess method', e);
        throw e;
      }
    }

    return result;
  };

  try {
    const result = await currentReq(token?.accessToken);

    return { data: result.data };
  } catch (error: unknown) {
    const err = error as AxiosError;
    if (err.response?.status === 401) {
      try {
        const { status, data } = await axiosInstance
          .post('/refresh', null, {
            headers: {
              Authorization: token.refreshToken,
            },
          })
          .then((data) => data)
          .then((result) => result);
        if (status === 200) {
          setTokens(data);
          const result = await currentReq(data.accessToken);

          return { data: result.data };
        }
      } catch (e) {
        window.localStorage.removeItem('token');
        window.location.replace('/auth');
        throw e;
      }
    }
    if (onReject) {
      onReject(dispatch);
    }
    return {
      error: error,
    };
  }
};
