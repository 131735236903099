export const getParsetQuery = (queryString = '') => {
  let keyValPairs: string[] = [];
  const params: { [x: string]: string } = {};
  queryString = queryString.replace(/.*?\?/, '');

  if (queryString.length) {
    keyValPairs = queryString.split('&');
    for (const pairNum in keyValPairs) {
      const key = keyValPairs[pairNum].split('=')[0];
      if (!key.length) continue;
      if (typeof params[key as string] === 'undefined') params[key] = keyValPairs[pairNum].split('=')[1];
    }
  }
  return params;
};
