import { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import isEmpty from 'lodash/isEmpty'
import { Navigate, useLocation } from 'react-router-dom'
import { localStorage } from 'src/ulils/localstorage'
import { getParsetQuery } from '../ulils/parseQuery'
import { useGetUserInfoQuery } from 'src/redux/API/User/Auth'

export const Autorized = ({ children }: { children: JSX.Element }) => {
  const { search, pathname } = useLocation()
  const token = localStorage({ key: 'token' }).getItem()
  const { pid } = getParsetQuery(search) ?? {}
  const user = useGetUserInfoQuery({})
  
  if (isEmpty(token)) {
    return <Navigate to="/auth" state={{ pid, pathname }} />
  }
  
  if (!user.data) return null
  
  return (
    <Fragment>
      <Helmet>
        <script
          src="https://miro.com/app/board/uXjVN4rfXwU=/?moveToWidget=3458764597658991768&cot=14"
        ></script>
        <script
          src="https://cdn.botpress.cloud/webchat/v1/inject.js"
        ></script>
        <script
          src="https://mediafiles.botpress.cloud/765ad948-0bff-46d5-a753-aebc474b7d5b/webchat/config.js"
          defer
        ></script>
      </Helmet>
      {children}
    </Fragment>
  )
}
