import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty, unionBy } from 'lodash';
import { AllUserCoursesResponsData, CoursesResponsData, Pack, PackInfoData } from '../API/Courses/types';
import { getPackPercentage } from 'src/components/LHNavigation/CourseLHN/utils';

interface State {
  userCourses: {
    bought: AllUserCoursesResponsData;
    no_bought: AllUserCoursesResponsData;
  };
  packInfo: PackInfoData | null;
  packs: { [id: string]: Pack } | null;
}

const initialState: State = {
  userCourses: {
    bought: {} as AllUserCoursesResponsData,
    no_bought: {} as AllUserCoursesResponsData,
  },
  packInfo: null,
  packs: null,
};

export const coursesSlice = createSlice({
  name: 'userCoursesSlice',
  initialState: initialState,
  reducers: {
    setUserCoursesSlice: (state, { payload }: PayloadAction<AllUserCoursesResponsData>) => {
      if (payload.bought) {
        const currentState = JSON.parse(JSON.stringify(state.userCourses));
        if (!isEmpty(currentState.bought.packs)) {
          const usiqData = unionBy([...currentState.bought.packs, ...payload.packs], (pack) => pack.id);
          state.userCourses.bought.packs = usiqData;
        } else {
          state.userCourses.bought = {
            ...state.userCourses.bought,
            ...payload,
          };
        }

        return state;
      } else {
        state.userCourses.no_bought = {
          ...state.userCourses.no_bought,
          ...payload,
        };
        return state;
      }
    },
    setCourseSlice: (state, { payload }: PayloadAction<CoursesResponsData>) => {
      const { pack, ...info } = payload;
      const oldPacks = JSON.parse(JSON.stringify(state.packs));
      return { ...state, packs: { ...oldPacks, [pack.id]: pack }, packInfo: info };
    },
    updateSeenVideo: (state, { payload }: PayloadAction<any>) => {
      const newVideo = {
        seen: payload.seen,
        PackModuleId: payload.packModule,
        VideoId: payload.video,
      };
      const packInfo = JSON.parse(JSON.stringify(state.packInfo));
      const packs = JSON.parse(JSON.stringify(state.packs));
      // const videoIndex = packInfo.videoHistory.findIndex(({ VideoId }: any) => VideoId === newVideo.VideoId);
      const newPackInfo = {
        ...packInfo,
        videoHistory: [...packInfo.videoHistory, newVideo],
      };
      const packData = {
        ...(state.packInfo ? newPackInfo : {}),
        pack: packs ? packs[payload.pack] : {},
      };
      const percentage = getPackPercentage(packData);

      let newUserCourses = JSON.parse(JSON.stringify(state.userCourses));
      if (state.userCourses.bought?.packs) {
        const packIndex = state.userCourses.bought.packs.findIndex(({ id }) => id === payload.pack);
        const newPack = newUserCourses.bought.packs[packIndex];
        newPack.seenVideoPercentage = percentage;
        newUserCourses = {
          ...newUserCourses,
          bought: {
            ...newUserCourses.bought,
            packs: [
              ...newUserCourses.bought.packs.slice(0, packIndex),
              newPack,
              ...newUserCourses.bought.packs.slice(packIndex + 1),
            ],
          },
        };
      }
      return { ...state, userCourses: newUserCourses, packInfo: newPackInfo };
    },
  },
});

export const { setUserCoursesSlice, setCourseSlice, updateSeenVideo } = coursesSlice.actions;
