import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserInfoResponsData, UserType } from '../API/User/types';
import { setTokens } from 'src/ulils/localstorage';

interface State {
  user: UserInfoResponsData | null;
}

const initialState: State = { user: null };

export const userSlice = createSlice({
  name: 'userSlice',
  initialState: initialState,
  reducers: {
    setUserSlice: (state, { payload: { email, accessToken, refreshToken, ...other } }: PayloadAction<UserType>) => {
      if (accessToken && refreshToken) {
        setTokens({ accessToken, refreshToken });
      }
      if (email) {
        return { ...state, user: { email, ...other } };
      }
      return { ...state, user: state.user };
    },
  },
});

export const { setUserSlice } = userSlice.actions;
