import { api } from 'src/redux/API/api';
import { setUserSlice } from 'src/redux/slices/userSlice';
import {
  UserInfoResponsData,
  SingInResponsData,
  SingInRequestData,
  SingUpRequestData,
  UpdateUserInfo,
  SingUpResponsData,
  UserType,
} from './types';

export const authApi = api.enhanceEndpoints({ addTagTypes: ['Users', 'User'] }).injectEndpoints({
  endpoints: (build) => ({
    singIn: build.mutation<SingInResponsData, SingInRequestData>({
      query: ({ email, password }) => ({
        url: `/students/signin`,
        method: 'POST',
        data: { email, password },
        onSuccess: async (dispatch, data) => {
          const response = data as UserType;
          dispatch(setUserSlice({ ...response, email, password }));
        },
      }),
    }),

    singUp: build.mutation<SingUpResponsData, SingUpRequestData>({
      query: ({ email, fullName, password, type, pid }) => ({
        url: `/students${type === "fake" ? "/fake" : ""}`,
        method: 'POST',
        data: { email, fullName, password, pid },
      }),
    }),

    getUserInfo: build.query<UserInfoResponsData, { newPassword?: string }>({
      query: () => ({
        url: `/students/me`,
        method: 'GET',
        onSuccess: async (dispatch, data) => {
          const response = data as UserType;
          dispatch(setUserSlice({ ...response }));
        },
      }),
      providesTags: ['User'],
    }),

    updateUserInfo: build.mutation<UpdateUserInfo, { newPassword?: string; toast?: any }>({
      query: ({ newPassword, toast, ...data }) => ({
        url: `/students`,
        method: 'PATCH',
        data: { password: newPassword, ...data },
        onSuccess: async () => {
          toast && toast.success('The data was saved successfully!!');
        },
        onReject: async () => {
          toast &&
            toast.error(
              'There was an error. Something went wrong during the submission. Please check the entered data.'
            );
        },
      }),
      invalidatesTags: ['User'],
    }),

    verificationCode: build.mutation<SingInResponsData, { studentId: string | number; code: string | number }>({
      query: ({ studentId, code }) => ({
        url: `/students/${studentId}/confirm/${code}`,
        method: 'GET',
        onSuccess: async (dispatch, data) => {
          const response = data as UserType;
          dispatch(setUserSlice(response));
        },
      }),
    }),

    resetEmail: build.mutation<SingInResponsData, { email: string }>({
      query: ({ email }) => ({
        url: `/students/password-reset/${email}`,
        method: 'GET',
      }),
    }),

    resetPassword: build.mutation<SingInResponsData, { password: string; code: string }>({
      query: (data) => ({
        url: `/students/password-reset`,
        method: 'POST',
        data,
      }),
    }),
  }),
});

export const {
  useSingInMutation,
  useSingUpMutation,
  useGetUserInfoQuery,
  useUpdateUserInfoMutation,
  useVerificationCodeMutation,
  useResetEmailMutation,
  useResetPasswordMutation,
} = authApi;
