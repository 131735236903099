import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { api } from './API/api';
import { userSlice } from './slices/userSlice';
import { coursesSlice } from './slices/coursesSlice';

const makeStore = () => {
  const store = configureStore({
    reducer: {
      [api.reducerPath]: api.reducer,
      userSlice: userSlice.reducer,
      coursesSlice: coursesSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(api.middleware),
  });
  return store;
};

export type AppStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<AppStore['getState']>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action>;

export type AppDispatch = AppStore['dispatch'];

export const store = makeStore();
