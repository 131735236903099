import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { navbarForDesktop, navbarForMobileDevice, roots } from 'src/routes/root';
import { store } from 'src/redux/store';
import { Provider } from 'react-redux';
import { Suspense } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

function App() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 976px)' });

  const courseLHNRoots = isTabletOrMobile ? navbarForMobileDevice : navbarForDesktop;

  return (
    <Suspense>
      <Provider store={store}>
        <RouterProvider router={createBrowserRouter([...roots, ...courseLHNRoots])} />
      </Provider>
    </Suspense>
  );
}

export default App;
