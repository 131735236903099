import React, { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import { Autorized } from 'src/hoc/Autorized'

const Auth = lazy(() => import('src/layouts').then(
  ({ AuthLayout }) => ({ default: AuthLayout })))
const Home = lazy(() => import('src/layouts').then(
  ({ HomeLayout }) => ({ default: HomeLayout })))
const Course = lazy(() => import('src/layouts').then(
  ({ CourseLayout }) => ({ default: CourseLayout })))

const SingInPage = lazy(
  () => import('src/pages').then(({ SingIn }) => ({ default: SingIn })))
const SingUpPage = lazy(
  () => import('src/pages').then(({ SingUp }) => ({ default: SingUp })))
const MyCoursesPage = lazy(
  () => import('src/pages').then(({ MyCourses }) => ({ default: MyCourses })))
// const CatalogPage = lazy(
//   () => import('src/pages').then(({ Catalog }) => ({ default: Catalog })))
const AccountPage = lazy(
  () => import('src/pages').then(({ Account }) => ({ default: Account })))
const ModulePage = lazy(
  () => import('src/pages').then(({ Module }) => ({ default: Module })))
const ResultPage = lazy(
  () => import('src/pages').then(({ Result }) => ({ default: Result })))
const TruckDispatcherFreeModule = lazy(() =>
  import('src/pages').then(({ TruckDispatcherFreeModule }) => ({
    default: TruckDispatcherFreeModule,
  })),
)

const VerificationCodePage = lazy(() =>
  import('src/pages').then(({ VerificationCode }) => ({
    default: VerificationCode,
  })),
)
const PasswordPage = lazy(() =>
  import('src/pages').then(({ Password }) => ({
    default: Password,
  })),
)

const ProfilePage = lazy(() =>
  import('src/pages').then(({ Profile }) => ({
    default: Profile,
  })),
)

const ResetEmailPage = lazy(() =>
  import('src/pages').then(({ ResetEmail }) => ({
    default: ResetEmail,
  })),
)
const ResetPasswordPage = lazy(() =>
  import('src/pages').then(({ ResetPassword }) => ({
    default: ResetPassword,
  })),
)
const ForgotPasswordPage = lazy(() =>
  import('src/pages').then(({ ForgotPassword }) => ({
    default: ForgotPassword,
  })),
)
const CheckEmailePage = lazy(() =>
  import('src/pages').then(({ CheckEmaile }) => ({
    default: CheckEmaile,
  })),
)
const Certificate = lazy(() =>
  import('src/pages').then(({ Certificate }) => ({
    default: Certificate,
  })),
)

export const navbarForMobileDevice = [
  {
    path: '/course/:packId?/:moduleId?/:lessonId?',
    element: (
      <Autorized>
        <Course />
      </Autorized>
    ),
  },
  {
    path: '/course/:packId/:moduleId/:lessonId/open?',
    element: (
      <Autorized>
        <ModulePage />
      </Autorized>
    ),
  },
  {
    path: 'course/:packId/:moduleId/:lessonId/open?/result',
    element: (
      <Autorized>
        <ResultPage />
      </Autorized>
    ),
  },
]

export const navbarForDesktop = [
  {
    path: '/course/:packId/:moduleId?/:lessonId?/open?',
    element: (
      <Autorized>
        <Course />
      </Autorized>
    ),
    children: [
      {
        index: true,
        element: (
          <Autorized>
            <ModulePage />
          </Autorized>
        ),
      },
      {
        path: 'result',
        element: (
          <Autorized>
            <ResultPage />
          </Autorized>
        ),
      },
    ],
  },
]

export const roots = [
  {
    path: '/auth',
    element: <Auth />,
    children: [
      {
        index: true,
        element: <SingInPage />,
      },
      {
        path: 'signup',
        element: <SingUpPage />,
      },
      {
        path: 'verify',
        element: <VerificationCodePage />,
      },
      {
        path: '/auth/reset',
        element: <ResetPasswordPage />,
      },
      {
        path: 'reset/email',
        element: <ResetEmailPage />,
      },
      {
        path: 'reset/password',
        element: <ForgotPasswordPage />,
      },
      {
        path: 'reset/password/:email',
        element: <CheckEmailePage />,
      },
    ],
  },
  {
    path: '/',
    element: (
      <Autorized>
        <Home />
      </Autorized>
    ),
    children: [
      {
        element: (
          <Autorized>
            <MyCoursesPage />
          </Autorized>
        ),
        index: true,
        errorElement: <div>error</div>,
      },
      // {
      //   path: 'catalog',
      //   element: (
      //     <Autorized>
      //       <CatalogPage />
      //     </Autorized>
      //   ),
      //   errorElement: <div>error</div>,
      // },
      {
        path: 'account',
        element: (
          <Autorized>
            <AccountPage />
          </Autorized>
        ),
        errorElement: <div>error</div>,
      },
      {
        path: 'account/profile',
        element: (
          <Autorized>
            <ProfilePage />
          </Autorized>
        ),
        errorElement: <div>error</div>,
      },
      {
        path: 'account/password',
        element: (
          <Autorized>
            <PasswordPage />
          </Autorized>
        ),
        errorElement: <div>error</div>,
      },
    ],
  },
  {
    path: '/freelesson',
    element: <TruckDispatcherFreeModule />,
  },
  {
    path: '/certificate',
    element: <Certificate />,
    errorElement: <div>error</div>,
  },
  {
    path: '*',
    element: (
      <Autorized>
        <Navigate replace to="/" />
      </Autorized>
    ),
  },
]
