export const localStorage = <T>({ key, data }: LocalStorage<T>) => {
  const local = window.localStorage;
  return {
    getItem: () => JSON.parse(local.getItem(key) ?? '{}'),
    setItem: () => local.setItem(key, JSON.stringify(data)),
    clear: () => local.clear(),
    deleteItem: () => local.removeItem(key),
  };
};

interface LocalStorage<T> {
  key: string;
  data?: T;
}

export const setTokens = ({ accessToken, refreshToken }: { accessToken: string; refreshToken: string }) => {
  localStorage({
    key: 'token',
    data: {
      accessToken,
      refreshToken,
    },
  }).setItem();
};
